import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function TeamStyle2({ data }) {
  const [structuredData, setStructuredData] = useState({
    social: [],
  });

  useEffect(() => {
    // Initialize a temporary structure to hold the categorized data
    const tempData = {
      social: [],
    };

    // Iterate over the doctor data keys
    for (const key in data) {
      if (data?.hasOwnProperty(key)) {
        const value = data?.[key];

        // Check for social-related keys
        if (key.startsWith("socialIcon")) {
          const index = key.replace("socialIcon", ""); // Get the index (one, two, etc.)
          const socialLinkKey = `socialLink${index}`;
          const socialTitleKey = `socialTitle${index}`;
          tempData.social.push({
            icon: value,
            link: data[socialLinkKey] || "",
            title: data[socialTitleKey] || "",
          });
        }
      }
    }

    // Update the state with the categorized data
    setStructuredData(tempData);
  }, [data]);
  return (
    <div className="cs_team cs_style_1 cs_type_2 text-center cs_radius_20 overflow-hidden">
      <div className="cs_member_img">
        <Link to={data?.slug} className="d-block">
          <img
            src={`${process.env.REACT_APP_API_URL}/api/images/${data?.image}`}
            alt="Doctor"
          />
        </Link>
        <div className="cs_label cs_white_color cs_accent_bg">
          {data?.designation}
        </div>
      </div>
      <div className="cs_team_meta cs_white_bg">
        <div>
          <h3 className="cs_member_name cs_fs_32">
            <Link to={data?.slug}>{data?.name}</Link>
          </h3>
          <p className="cs_member_designation cs_heading_color cs_medium">
            {data?.designation}
          </p>
          <p className="cs_member_description">{data?.description}</p>
        </div>
        <div>
          <div className="cs_social_links">
            {structuredData?.social?.map((item, index) => (
              <Link to={item.href} key={index}>
                <Icon icon={item.icon} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
