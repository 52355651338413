import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function MenuWidget({ data }) {
  const { t, i18n } = useTranslation();

  return (
    <ul className="cs_menu_widget cs_mp0">
      {data?.map((item, index) => (
        <li key={index}>
          <Link to={item.href}>
            {i18n.language === "ar" ? item.titleAr : item.titleEn}
          </Link>
        </li>
      ))}
    </ul>
  );
}
