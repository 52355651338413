import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Section from "../Section";
import Breadcrumb from "../Breadcrumb";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import { Icon } from "@iconify/react";
import Spacing from "../Spacing";
import Sidebar from "../Sidebar";
import AuthorWidget from "../Widget/AuthorWidget";
import { pageTitle } from "../../helpers/PageTitle";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";
import { fetchBlogs } from "./Home";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
const fetchBlog = async (lang, blogId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/articles/posts/${blogId}?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
export default function BlogDetails() {
  const { blogId } = useParams();
  const { data: blogsData } = useFetchData(['blogs'], fetchBlogs);
  const [categoryMap, setCategoryMap] = useState();
  const { t } = useTranslation("common");
  const { data, error, isLoading } = useFetchData(
    ["blogs", blogId], // Query key with additional parameter
    (lang) => fetchBlog(lang, blogId) // Pass a function that `useFetchData` can call with `lang`
  );
  const url = window.location.href;
  useEffect(() => {
    const categoriesSet = new Set();
    blogsData?.data?.data?.forEach(article => {
      categoriesSet.add(article.data.category[0]);
    });
    setCategoryMap([...categoriesSet])

  }, [blogsData]);
  pageTitle("Blog Details");
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={data?.data?.data?.title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags">
              {data?.data?.data?.tags.map((item, index) => (
                <Link key={index} to={`/blog?tag=${item}`}>
                  {item}
                </Link>
              ))}
            </div>
            <div className="cs_blog_details_date">
              {new Date(data?.data?.publishedAt)
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })
                .replace(/ /g, ".")}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>{t("utils.Share")}:</h2>
            <div className="cs_social_links">
              <FacebookShareButton url={url}>
                <Link >
                  <Icon icon="fa-brands:facebook-f" />
                </Link>
              </FacebookShareButton>
              <TwitterShareButton url={url}>
                <Link >
                  <Icon icon="fa-brands:twitter" />
                </Link>
              </TwitterShareButton>
              <LinkedinShareButton url={url}>
                <Link >
                  <Icon icon="fa-brands:linkedin-in" />
                </Link>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={`${process.env.REACT_APP_API_URL}/api/images/${data?.data?.data.image}`}
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div
              dangerouslySetInnerHTML={{ __html: data?.data?.data?.content }}
            />
            <Spacing md="60" />
            <AuthorWidget
              label={t("utils.author")}
              name={data?.data?.data?.author}
            />
          </div>
          <div className="col-lg-4">
            <Sidebar t={t} categories={categoryMap} />
          </div>
        </div>
        <Spacing md="135" lg="100" />
      </div>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title={t("bannerTitle")}
          subTitle={t("bannerSubtitle")}
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
