import React, { useContext } from "react";
import parser from "html-react-parser";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/common/LanguageContext";

export default function IconBoxStyle6({ title, subTitle, icon, slug }) {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <div className="cs_iconbox cs_style_7">
      <div className="cs_iconbox_icon">
        <img src={`${process.env.REACT_APP_API_URL}/api/images/${icon}`} alt="Icon" />
      </div>
      <h2 className="cs_iconbox_title cs_fs_32">{parser(title)}</h2>
      <p className="cs_iconbox_subtitle m-0">{parser(subTitle)}</p>
      <Link to={`/products/${slug}`} className="cs_iconbox_btn cs_center">
        {currentLanguage === "en" ? (
          <>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </>
        ) : (
          <>
            <img src="/images/icons/left_arrow_blue.svg" alt="Icon" />
            <img src="/images/icons/left_arrow_blue.svg" alt="Icon" />
          </>
        )}
      </Link>
    </div>
  );
}
