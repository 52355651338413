import React from "react";

export default function ContactForm({ t, i18n }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    fetch("https://formspree.io/f/xovqdlel", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = "/";
        } else {
          alert("There was a problem with the submission.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("There was an error submitting the form.");
      });
  };

  return (
    <form onSubmit={handleSubmit} className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            {t("contactComponent.nameLabel")}
          </label>
          <input
            type="text"
            className="cs_form_field"
            name="name"
            placeholder={t("contactComponent.namePlaceholder")}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            {t("contactComponent.emailLabel")}
          </label>
          <input
            type="email"
            name="email"
            className="cs_form_field"
            placeholder={t("contactComponent.emailPlaceholder")}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            {t("contactComponent.subjectLabel")}
          </label>
          <input
            type="text"
            name="subject"
            className="cs_form_field"
            placeholder={t("contactComponent.subjectPlaceholder")}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            {t("contactComponent.messageLabel")}
          </label>
          <textarea
            cols={30}
            rows={10}
            name="message"
            className="cs_form_field"
            placeholder={t("contactComponent.messagePlaceholder")}
            defaultValue=""
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button className="cs_btn cs_style_1">
            <span>{t("contactComponent.submitButton")}</span>
            <i>
              {i18n.language === "en" ? (
                <>
                  <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
                  <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
                </>
              ) : (
                <>
                  <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
                  <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
                </>
              )}
            </i>
          </button>
        </div>
      </div>
    </form>
  );
}
