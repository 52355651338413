import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/common/LanguageContext";

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const { currentLanguage } = useContext(LanguageContext);
  const [sended, setSended] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    setSended(true);
    const formData = new FormData(form);
    fetch("https://formspree.io/f/mvgoaqkw", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // window.location.href = "/";
          setSended(false)
        } else {
          alert("There was a problem with the submission.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("There was an error submitting the form.");
      });
  };
  return (
    <>
      {label && <p>Your Email</p>}
      <form onSubmit={handleSubmit} className="cs_newsletter_form">
        <input
          type="text"
          className="cs_form_field"
          name="email"
          placeholder="example@email.com"
        />
        <button className="cs_btn cs_style_1">
          <span>{!sended ? btnText : "sending..."}</span>
          <i>
            {currentLanguage === "en" ? (
              <>
                <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
                <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
              </>
            ) : (
              <>
                <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
                <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
              </>
            )}
          </i>
        </button>
      </form>
    </>
  );
}
