import React from "react";
import HeroStyle3 from "../Hero/HeroStyle3";
import Section from "../Section";
import BrandsSection from "../Section/BrandsSection";
import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import FeaturesSectionStyle4 from "../Section/FeaturesSection/FeaturesSectionStyle4";
import DepartmentSectionStyle5 from "../Section/DepartmentSection/DepartmentSectionStyle5";
import TestimonialSectionStyle3 from "../Section/TestimonialSection/TestimonialSectionStyle3";
import BannerSectionStyle8 from "../Section/BannerSection/BannerSectionStyle8";
import TeamSectionStyle4 from "../Section/TeamSection/TeamSectionStyle4";
import FaqSectionStyle3 from "../Section/FaqSection/FaqSectionStyle3";
import BlogSectionStyle4 from "../Section/BlogSection/BlogSectionStyle4";
import { pageTitle } from "../../helpers/PageTitle";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";

export const fetchBrands = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/logo/brands?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export const fetchProducts = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/projects/projects?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

const fetchTestmonials = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/testimonials/testimonials?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
const fetchFAQ = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/faqs?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export const fetchBlogs = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/articles/posts?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
export const fetchDoctors = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/team/teams?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
const fetchServices = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/services/services?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
export default function Home() {
  pageTitle("Home");
  const {
    data,
    error: doctorsError,
  } = useFetchData(["doctors"], fetchDoctors);
  const {
    data: blogsData,
    error: blogsError,
  } = useFetchData(["blogs"], fetchBlogs);
  const {
    data: brandsData,
  } = useFetchData(["brands"], fetchBrands);
  const {
    data: FAQData,
    error: FAQError,
  } = useFetchData(["FAQ"], fetchFAQ);
  const {
    data: testmonialsData,
  } = useFetchData(["testmonials"], fetchTestmonials);
  const {
    data: productsData,
  } = useFetchData(["products"], fetchProducts);
  const {
    data: servicesData,
  } = useFetchData(["services"], fetchServices);
  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation("common");
  const { t: tAbout, i18n } = useTranslation("about");
  return (
    <div>
      <HeroStyle3
        title={t("mainTitle")}
        subTitle={t("mainDescription")}
        bgUrl="/images/home_3/hero_bg.svg"
        imgUrl="/images/home_3/hero_bg.png"
        imgUrl2={i18n.language === "ar" ? "/images/home_3/hero_img_2_ar.png" : "/images/home_3/hero_img_2.png"}
        imgUrl4="/images/home_3/hero_img_4.png"
        avatarImgUrl="/images/home_3/hero_avatar.png"
        avatarName="Dr. Michele Amber, MD"
        avatarSpeciality="Cardiologist"
        avatarSheduleList={[
          {
            icon: "fa6-solid:calendar-days",
            title: "Days :",
            details: "Monday - Thursday",
          },
          {
            icon: "fa6-regular:clock",
            title: "Time :",
            details: "09.00 Am - 07.00 PM",
          },
        ]}
      />
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection
          data={brandsData?.data?.logos}
          sectionTitle={tCommon("partnersTitle")}
        />
      </Section>
      <Section>
        <AboutSectionStyle4
          imgUrl="/images/home_3/about1.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title={tAbout("mainTitle")}
          subTitle={tAbout("mainDesc")}
        />
      </Section>
      <Section topMd={200} topLg={150} topXl={110}>
        <FeaturesSectionStyle4
          sectionTitle={tAbout("valuesTitle")}
          data={tAbout("valuesArray", { returnObjects: true })}
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={175}
        bottomLg={125}
        bottomXl={85}
      >
        <DepartmentSectionStyle5
          sectionTitle={tCommon("productSubtitle")}
          sectionTitleUp={tCommon("productsTitle")}
          data={servicesData?.data?.data}
        />
      </Section>
      <Section
        className="cs_bg_filed"
        style={{ backgroundImage: "url(/images/home_3/testimonial_bg.svg)" }}
      >
        <Section
          topMd={190}
          topLg={145}
          topXl={105}
          bottomMd={200}
          bottomLg={150}
          bottomXl={i18n.language === "ar" ? 180 : 110}
        >
          <TestimonialSectionStyle3
            sectionTitle={tCommon("testimonialSubtitle")}
            sectionTitleUp={tCommon("testimonialTitle")}
            data={testmonialsData?.data?.testimonials}
          />
        </Section>
        <BannerSectionStyle8
          imgUrl="/images/home_3/banner_img.png"
          bgUrl="/images/home_3/banner_bg.svg"
          title={t("bannerOneTitle")}
          subTitle={t("bannerOneDesc")}
        />
      </Section>
      {!doctorsError && (
        <Section topMd={195} topLg={145} topXl={105}>
          <TeamSectionStyle4
            sectionTitle={tCommon("teamSubtitle")}
            sectionTitleUp={tCommon("teamTitle")}
            data={data?.data?.data}
          />
        </Section>
      )}
      {!blogsError && (
        <Section topMd={160} topLg={120} topXl={70}>
          <BlogSectionStyle4
            sectionTitle={tCommon("blogSubtitle")}
            sectionTitleUp={tCommon("blogTitle")}
            data={blogsData?.data?.data}
          />
        </Section>
      )}
      {!FAQError && (
        <Section topMd={190} topLg={145} topXl={105}>
          <FaqSectionStyle3
            data={FAQData?.data}
            sectionTitle={tCommon("faqSubtitle")}
            sectionTitleUp={tCommon("faqTitle")}
            sectionTitleDown=""
            sectionSubTitle=""
          />
        </Section>
      )}
    </div>
  );
}
