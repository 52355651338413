import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import IconBoxStyle9 from '../../IconBox/IconBoxStyle9';

export default function DepartmentSectionStyle5({
  sectionTitle,
  sectionTitleUp,
  data,
}) {
  return data?.length > 0 && (
    <div className="container">
      <div className="row align-items-center flex-xl-row flex-column-reverse">
        <div className="col-xl-8">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle9 href={`#`} title={data[0]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[0]?.data?.icon}`} />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle9 title={data[1]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[1]?.data?.icon}`} />
            </div>
            {data?.length > 2 && (
              <>
                <div className="col-lg-3" />
                <div className="col-lg-3" />
                <div className="col-lg-3" /></>
            )}

            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle9 title={data[2]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[2]?.data?.icon}`} />
            </div>
            {data?.length > 3 && (
              <div className="col-lg-3 col-sm-6">
                <IconBoxStyle9 title={data[3]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[3]?.data?.icon}`} />
              </div>
            )}
            {data?.length > 4 && (
              <>
                <div className="col-lg-3" />
                <div className="col-lg-3" />
                <div className="col-lg-3" />
              </>
            )}
            {data?.length > 5 && (
              <div className="col-lg-3 col-sm-6">
                <IconBoxStyle9 title={data[4]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[4]?.data?.icon}`} />
              </div>

            )}
            {data?.length > 6 && (
              <div className="col-lg-3 col-sm-6">
                <IconBoxStyle9 title={data[5]?.data?.title} iconUrl={`${process.env.REACT_APP_API_URL}/api/images/${data[5]?.data?.icon}`} />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-4">
          <div className="text-end">
            <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
          </div>
          <Spacing md="72" lg="50" />
        </div>
      </div>
    </div>
  );
}
