import React from 'react';
import Spacing from '../../Spacing';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}
export default function BrandsSection({ data, sectionTitle }) {

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 100,
    rtl: true,
    cssEase: "linear",
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <div className="container">
      {sectionTitle && (
        <>
          <h2 className="cs_fs_40 text-center mb-0 cs_semibold">
            {sectionTitle}
          </h2>
          <Spacing md="60" />
        </>
      )}

      <Slider {...settings}>
        {data?.map((item, index) => (
          <div className="cs_brand cs_center" key={index} style={{ minWidth: '100%' }}>
            <Link to={item.link}>
              <img style={{
                width: "150px"
              }} title={item.name} src={`${process.env.REACT_APP_API_URL}/api/images/${item.logo}`} alt={item.name} />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}
