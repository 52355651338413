import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import AppointmentWithContactInfoSection from "../Section/AppointmentWithContactInfoSection";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import { useTranslation } from "react-i18next";

export default function Appointments() {
  pageTitle("Appointments");
  const { t } = useTranslation("common");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/appointments/banner_img.png"
        title={t("bannerTitle")}
        subTitle={t("bannerSubtitle")}
      />
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AppointmentWithContactInfoSection t={t} />
      </Section>
    </>
  );
}
