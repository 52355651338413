import React, { useEffect } from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import DepartmentSectionStyle2 from "../Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";
import FunFactSection from "../Section/FunFactSection";
import TeamSection from "../Section/TeamSection";
import GallerySection from "../Section/GallerySection";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import { useFetchData } from "../../App";
import { fetchDoctors } from "./Home";
import { useTranslation } from "react-i18next";
import Spacing from "../Spacing";

const funFactData = [
  { number: "20+", title: "Years of experience", titleAr: "سنوات من الخبرة" },
  {
    number: "95%",
    title: "Patient satisfaction rating",
    titleAr: "معدل رضا المرضى",
  },
  {
    number: "5000+",
    title: "Patients served annually",
    titleAr: "المرضى الذين نخدمهم سنوياً",
  },
  {
    number: "10+",
    title: "Healthcare providers on staff",
    titleAr: "مزودو الرعاية الصحية ضمن الفريق",
  },
  {
    number: "22+",
    title: "Convenient locations in the area",
    titleAr: "مواقع مريحة في المنطقة",
  },
];

const galleryData = [
  { imgUrl: "/images/about/portfolio_2_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_3_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_1_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
];
// const awardData = [
//   {
//     iconUrl: "/images/icons/award.svg",
//     title: "Malcolm Baldrige National Quality Award",
//   },
//   { iconUrl: "/images/icons/award.svg", title: "HIMSS Davies Award" },
//   {
//     iconUrl: "/images/icons/award.svg",
//     title: "Healthgrades National’s Best Hospital",
//   },
//   {
//     iconUrl: "/images/icons/award.svg",
//     title: "Joint Commission Gold Seal of Approval",
//   },
// ];

const fetchServices = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/services/services?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export default function About() {
  pageTitle("About");
  const {
    data: doctorsData,
    error,
    isLoading,
  } = useFetchData(["doctors"], fetchDoctors);
  const {
    data: servicesData,
    error: servicesError,
    isLoading: servicesIsLoading,
  } = useFetchData(["services"], fetchServices);
  const { t, i18n } = useTranslation("about");
  const { t: tCommon } = useTranslation("common");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title={t("mainTitle")}
        subTitle={t("mainDesc")}
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle={tCommon("productSubtitleSecond")}
          sectionTitleUp={tCommon("productsTitle")}
          data={servicesData?.data?.data}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle={t("valuesTitle")}
          imgUrl="images/about/why_choose_us.jpeg"
          data={t("valuesArray", { returnObjects: true })}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle={tCommon("teamSubtitle")}
          sectionTitleUp={tCommon("teamTitle")}
          data={doctorsData?.data?.data}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle={tCommon("facilitiesSubtitle")}
          sectionTitleUp={tCommon("facilitiesTitle")}
          data={galleryData}
        />
      </Section>
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section> */}

      <Section topMd={170} topLg={120} topXl={80} className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title={tCommon("bannerTitle")}
          subTitle={tCommon("bannerSubtitle")}
          center
        />
      </Section>
    </>
  );
}
