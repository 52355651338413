import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Doctors from "./components/Pages/Doctors";
import Blog from "./components/Pages/Blog";
import Appointments from "./components/Pages/Appointments";
import Departments from "./components/Pages/Departments";
import DepartmentDetails from "./components/Pages/DepartmentDetails";
import BlogDetails from "./components/Pages/BlogDetails";
import DoctorDetails from "./components/Pages/DoctorDetails";
import PricingPlan from "./components/Pages/PricingPlan";
import Gallery from "./components/Pages/Gallery";
import Timetable from "./components/Pages/Timetable";
import Contact from "./components/Pages/Contact";
import { useEffect, useState } from "react";
import ErrorPage from "./components/Pages/ErrorPage";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import Privacy from "./components/Pages/Privacy";
import Terms from "./components/Pages/Terms";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000, // 30 minutes
      cacheTime: 60 * 60 * 1000, // 1 hour
      refetchOnWindowFocus: true, // Automatically refetch on window focus
      refetchOnReconnect: true, // Automatically refetch on network reconnect
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});
// Global loading state
let globalLoadingState = {
  isLoading: false,
  setLoading: null,
};

// Centralized loading spinner component
function GlobalLoadingSpinner() {
  return globalLoadingState.isLoading ? <div>Loading...</div> : null;
}

// Custom hook for fetching data with centralized loading, error handling, and i18n support
export function useFetchData(queryKey, fetchFunction, options = {}) {
  const { i18n } = useTranslation(); // Access i18n for language changes
  const [loading, setLoading] = useState(false);

  // Centralize the loading state
  useEffect(() => {
    globalLoadingState.setLoading = setLoading;
  }, []);

  // Refetch data automatically when the language changes
  useEffect(() => {
    queryClient.invalidateQueries(queryKey); // Invalidate cache to refetch data
  }, [i18n.language]); // Dependency on language change

  // Set query options with persistence
  const queryOptions = {
    staleTime: 5 * 60 * 1000, // Optional: fine-tune per-query staleTime
    cacheTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      globalLoadingState.setLoading(false);
      localStorage.setItem("loadingState", JSON.stringify(false));
    },
    onError: () => {
      globalLoadingState.setLoading(false);
      localStorage.setItem("loadingState", JSON.stringify(false));
    },
    ...options,
  };

  // Use React Query's `useQuery` with persistence and i18n integration
  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      globalLoadingState.setLoading(true);
      localStorage.setItem("loadingState", JSON.stringify(true));
      return fetchFunction(i18n.language);
    },
    ...queryOptions,
  });

  // Retrieve persisted loading state from local storage
  useEffect(() => {
    const persistedLoading = JSON.parse(localStorage.getItem("loadingState"));
    if (persistedLoading !== null) {
      globalLoadingState.setLoading(persistedLoading);
    }
  }, []);

  return queryResult;
}

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalLoadingSpinner />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="doctors" element={<Doctors />} />
          <Route path="doctors/:doctorId" element={<DoctorDetails />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:blogId" element={<BlogDetails />} />
          <Route path="appointments" element={<Appointments />} />
          <Route path="products" element={<Departments />} />
          <Route path="products/:productId" element={<DepartmentDetails />} />
          <Route path="pricing-plan" element={<PricingPlan />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
