import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import TextWidget from "../Widget/TextWidget";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { fetchContactDetails } from "./../Pages/Contact";
import { useFetchData } from "../../App";
const menuDataOne = [
  { titleAr: "من نحن", titleEn: "About Us", href: "/about" },
  { titleAr: "المنتجات", titleEn: "Departments", href: "/departments" },
  { titleAr: "الأطباء", titleEn: "Doctors", href: "/doctors" },
  { titleAr: "حجز", titleEn: "Appointment", href: "/appointments" },
  { titleAr: "الآراء", titleEn: "Testimonials", href: "/" },
];
const menuDataTwo = [
  { titleAr: "المدونة", titleEn: "Blog", href: "/blog" },
  { titleAr: "اتصل بنا", titleEn: "Contact Us", href: "/contact" },
  { titleAr: "سياسة الخصوصية", titleEn: "Privacy Policy", href: "/privacy" },
  {
    titleAr: "الشروط والأحكام",
    titleEn: "Terms and Conditions",
    href: "/terms",
  },
];

export default function Footer() {
  const {
    data: contactData,
    error: contactError,
    isLoading: contactIsLoading,
  } = useFetchData(["contact"], fetchContactDetails);



  const { t } = useTranslation("common");
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: "url(/images/footer_logo_bg.svg)" }}
        >
          <img
            src="/images/logo_icon.svg"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
          <h2 className="cs_footer_brand_text">Ameesa Med</h2>
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text={t("footer.contactInfo.title")} />
                <ContactInfoWidget data={contactData?.data?.contact} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title={t("footer.form.title")}
                  subTitle={t("footer.form.desc")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">

              {t("copyright1")} <a style={{ color: "white" }} href="https://www.nexlla.com">Nexlla CA</a>   {t("copyright2")}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
