import { Icon } from "@iconify/react";
import React from "react";

export default function HeroStyle3({
  title,
  subTitle,
  bgUrl,
  imgUrl,
  imgUrl2,
  imgUrl3,
  imgUrl4,
  avatarImgUrl,
  avatarName,
  avatarSpeciality,
  avatarSheduleList,
}) {
  return (
    <section className="cs_hero cs_style_3">
      <div
        className="cs_hero_bg cs_bg_filed"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
      </div>
      <div className="container">
        <div className="cs_hero_text">
          <h1 className="cs_hero_title cs_fs_94">{title}</h1>
          <p className="cs_hero_subtitle cs_white_color cs_fs_20 m-0">
            {subTitle}
          </p>
        </div>
      </div>
      <img src={imgUrl} alt="Hero" className="cs_hero_img_1" />
      <img src={imgUrl2} alt="Hero" className="cs_hero_img_2" />
      <img src={imgUrl4} alt="Hero" className="cs_hero_img_4" />
    </section>
  );
}
