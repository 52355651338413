import React from "react";
import { useTranslation } from "react-i18next";

export default function FunFact({ number, title, titleAr }) {
  const { i18n } = useTranslation();

  return (
    <div className="cs_funfact cs_style_1 text-center">
      <h2 className="cs_funfact_number cs_fs_72">{number}</h2>
      <p className="cs_funfact_title m-0 cs_heading_color">
        {i18n.language === "ar" ? titleAr : title}
      </p>
    </div>
  );
}
