import React from 'react';
import NewsletterForm from '../NewsletterForm';
import { useTranslation } from 'react-i18next';

export default function Newsletter({ title, subTitle }) {
  const {t} = useTranslation('common');
  return (
    <div className="cs_newsletter cs_style_1">
      <h2 className="cs_newsletter_title">{title}</h2>
      <div className="cs_newsletter_subTitle">{subTitle}</div>
      <NewsletterForm
        btnText={t("submitButton")}
        btnArrowUrl="/images/icons/arrow_white.svg"
      />
    </div>
  );
}
