import React, { createContext, useState, useEffect } from "react";
import i18n from "../../i18n"; // Assuming you have an i18n.js setup as shown previously

// Create the context
const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  // Initialize state with the language stored in localStorage or default to 'en'
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  // Function to change the language
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language); // This changes the language in i18next
    localStorage.setItem("i18nextLng", language); // Save language to localStorage
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
