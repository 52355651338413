import { Icon } from "@iconify/react";
import React from "react";

export default function ContactInfoWidget({ data }) {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_accent_bg">
          <Icon icon="ep:location" />
        </i>
        {data?.firstAddress}
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        {data?.firstPhoneNumber}
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="bi:envelope" />
        </i>
        {data?.firstEmail}
      </li>
    </ul>
  );
}
