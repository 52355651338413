import React from "react";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
import { fetchBlogs } from "./Home";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";

export const fetchPrivacy = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/privacy?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export default function Privacy() {
  const { data, error, isLoading } = useFetchData(["privacy"], fetchPrivacy);
  pageTitle("Blog");
  const { t } = useTranslation("common");
  if (isLoading) return "loading...";
  if (error) return "error...";
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title={t("privacyMainTitle")} />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: data?.data?.privacyPolicy }}
        ></div>
      </Section>
    </>
  );
}
