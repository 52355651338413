import React, { useContext } from "react";
import { LanguageContext } from "../../context/common/LanguageContext";
import { FlagIcon } from "react-flag-kit";
import "../../styles/languageSwitcher.css";
const LanguageSwitcher = ({ style = 1 }) => {
  const { currentLanguage, changeLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (val) => {
    changeLanguage(val);
  };

  return (
    <div className="language-switcher">
      <button
        type="button"
        className="language-button"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        {currentLanguage.toUpperCase()}
      </button>

      <div
        className="language-menu"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <button
          className={`language-option ${
            currentLanguage === "en" ? "active" : ""
          }`}
          onClick={() => handleLanguageChange("en")}
          role="menuitem"
        >
          EN
        </button>
        <button
          className={`language-option ${
            currentLanguage === "ar" ? "active" : ""
          }`}
          onClick={() => handleLanguageChange("ar")}
          role="menuitem"
        >
          AR
        </button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
