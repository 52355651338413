import React from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";
export const fetchContactDetails = async (lang) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/contact?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export default function Contact() {
  const { t, i18n } = useTranslation("common");
  const {
    data: contactData,
    error: contactError,
    isLoading: contactIsLoading,
  } = useFetchData(["contact"], fetchContactDetails);
  pageTitle("Contact-Us");




  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        imgUrl="/images/contact/banner_img.png"
        title={t("contactusTitle")}
        subTitle={t("contactusDesc")}
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 formContact offset-lg-1">
            <ContactForm t={t} i18n={i18n} />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection
          t={t}
          sectionTitle={t("contactComponent.findUs")}
          data={contactData?.data?.contact}
        />
      </Section>
    </>
  );
}
