import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { LanguageContext } from "../../context/common/LanguageContext";

export default function Layout() {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <div dir={currentLanguage === "ar" ? "rtl" : "ltr"}>
      <Header logoSrc="/images/logo.png" variant="cs_heading_color" />
      <Outlet />
      <Footer />
    </div>
  );
}
