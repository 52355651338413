import { Icon } from "@iconify/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchProducts } from "../Pages/Home";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";

export default function AppointmentForm() {
  const {
    data: productsData,
    error: productsError,
    isLoading: productsIsLoading,
  } = useFetchData(["products"], fetchProducts);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const formattedDate = selectedDate.toISOString().split("T")[0]; // '2024-11-15'
    formData.append("prefferedDay", formattedDate);
    fetch("https://formspree.io/f/xovqdlel", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = "/";
        } else {
          alert("There was a problem with the submission.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("There was an error submitting the form.");
      });
  };
  const { t, i18n } = useTranslation("common");

  return (
    <form onSubmit={handleSubmit} className="row">
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.name")}
        </label>
        <input type="text" className="cs_form_field" name="name" placeholder="David John" />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.phoneNumber")}
        </label>
        <input
          type="text"
          name="phone_number"
          className="cs_form_field"
          placeholder="(123) 456 - 789"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.medicalRecordNumber")}
        </label>
        <input
          type="text"
          className="cs_form_field"
          name="medicalRecordNumber"
          placeholder="123456-7890-0987"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.prefferedDate")}
        </label>

        <div className="cs_with_icon_input">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
          />

        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.prefferedTime")}
        </label>
        <div className="cs_with_icon_input">
          <input
            type="time"
            className="cs_form_field cs_timepicker"
            placeholder="10:00AM"
            name="prefferedTime"
          />

        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.reasonForVisit")}
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="routineCheckup"
              defaultValue="routineCheckup"
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              {i18n.language === "en" ? "Routine Checkup" : "تحقق روتيني"}
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              defaultValue="newPatientVisit"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              {i18n.language === "en" ? "New Patient Visit " : "زيارة مريض جديدة"}
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              defaultValue="specificConcern"
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
              {i18n.language === "en" ? "Specific Concern" : "قلق محدد"}
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          {t("appointmentComponent.Product")}
        </label>
        <div className="cs_radio_group">
          {productsData?.data?.data?.map((ele) => (
            <div className="cs_radio_wrap">
              <input
                className="cs_radio_input"
                type="radio"
                name="product"
                id={ele?.data?.slug}
                value={ele?.data?.title}
              />
              <label className="cs_radio_label" htmlFor={ele?.data?.slug}>
                {ele?.data?.title}
              </label>
            </div>
          ))}
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>{t("submitButton")}</span>
          <i>
            {i18n.language === "en" ? (
              <>
                <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
                <img src="/images/icons/right_arrow_blue.svg" alt="Next" />
              </>
            ) : (
              <>
                <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
                <img src="/images/icons/left_arrow_blue.svg" alt="Prev" />
              </>
            )}
          </i>
        </button>
      </div>
    </form>
  );
}
