import React from "react";

export default function AuthorWidget({ imgUrl, name, label }) {
  return (
    <div className="">
      <div className="cs_author_right">
        <h3>
          {label}: {name}
        </h3>
      </div>
    </div>
  );
}
