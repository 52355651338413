import React from "react";
import Rating from "../Rating";

export default function TestimonialStyle3({ data }) {
  return (
    <div className="cs_testimonial cs_style_3 cs_radius_20 cs_white_bg text-center">
      <div className="cs_testimonial_img">
        <img
          src={`${process.env.REACT_APP_API_URL}/api/images/${data?.image}`}
          alt={data?.name}
          style={{
            borderRadius: "10%",
          }}
        />
      </div>
      <h2 className="cs_testimonial_avatar cs_fs_24 cs_semibold">
        {data?.name}
      </h2>
      <Rating ratingNumber={data?.stars} />
      <div className="cs_testimonial_text cs_fs_20">{data?.quote}</div>
    </div>
  );
}
