import React, { useEffect, useState } from "react";
import BannerSectionStyle7 from "../Section/BannerSection/BannerSectionStyle7";
import Section from "../Section";
import AppointmentSection from "../Section/AppointmentSection";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import AboutSectionStyle2 from "../Section/AboutSection/AboutSectionStyle2";
import FeaturesSectionStyle3 from "../Section/FeaturesSection/FeaturesSectionStyle3";
import TeamSectionStyle3 from "../Section/TeamSection/TeamSectionStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";

const featureData = [
  {
    title: "Vaccinations Department",
    subTitle:
      "The Pediatric Department provides vaccinations to help protect children from a range of illnesses and diseases, including measles.",
    iconUrl: "/images/departments/icon_9.svg",
  },
  {
    title: "Management of acute illnesses",
    subTitle:
      "The Pediatric Department provides treatment for common childhood illnesses, such as ear infections, strep throat, and viral infections.",
    iconUrl: "/images/departments/icon_10.svg",
  },
  {
    title: "Treatment of chronic conditions",
    subTitle:
      "The Pediatric Department provides ongoing care and treatment for children with chronic conditions such as asthma, diabetes, and allergies.",
    iconUrl: "/images/departments/icon_11.svg",
  },
  {
    title: "Developmental screenings",
    subTitle:
      "The Pediatric Department provides regular developmental screenings to identify any delays or concerns and provide early intervention services.",
    iconUrl: "/images/departments/icon_12.svg",
  },
];

const doctorData = [
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    availableUrl: "/",
    callUrl: "/",
    chatUrl: "/",
    btnText: "Booking",
    btnUrl: "/appointments",
  },
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    availableUrl: "/",
    callUrl: "/",
    chatUrl: "/",
    btnText: "Booking",
    btnUrl: "/",
  },
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    availableUrl: "/",
    callUrl: "/",
    chatUrl: "/",
    btnText: "Booking",
    btnUrl: "/",
  },
  {
    imgUrl: "/images/departments/related_doctor_1.jpeg",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex <br />medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
    availableUrl: "/",
    callUrl: "/",
    chatUrl: "/",
    btnText: "Booking",
    btnUrl: "/",
  },
];

export const fetchProduct = async (lang, slug) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/projects/projects/${slug}?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};

export default function DepartmentDetails() {
  const { productId } = useParams();
  const { t } = useTranslation("common");
  const {
    data: productData,
    error: productError,
    isLoading: productIsLoading,
  } = useFetchData(["product", productId], (lang) =>
    fetchProduct(lang, productId)
  );
  pageTitle("Department Details");
  const [structuredData, setStructuredData] = useState({
    treatments: [],
  });

  useEffect(() => {
    if (!productData?.data?.data?.data) return;

    // Initialize a temporary structure to hold the categorized data
    const tempData = {
      treatments: [],
    };

    // Iterate over the product data keys
    const productInfo = productData.data.data.data;
    for (const key in productInfo) {
      if (productInfo.hasOwnProperty(key)) {
        const value = productInfo[key];

        // Check for treatment-related keys
        if (key.startsWith("treatmentIcon")) {
          const index = key.replace("treatmentIcon", ""); // Extract the index (One, Two, etc.)
          const titleKey = `treatmentTitle${index}`;
          const subtitleKey = `treatmentSubtitle${index}`;

          tempData.treatments.push({
            icon: value,
            title: productInfo[titleKey] || "",
            subTitle: productInfo[subtitleKey] || "",
          });
        }
      }
    }

    // Update the state with the categorized data
    setStructuredData(tempData);
  }, [productData]);
  return (
    <>
      <BreadcrumbStyle2 />
      <Section topMd={135} topLg={100} topXl={100}>
        <AboutSectionStyle2
          title={productData?.data?.data?.data?.title}
          subTitle={productData?.data?.data?.data?.subTitle}
          imgUrl={`${process.env.REACT_APP_API_URL}/api/images/${productData?.data?.data?.data?.image}`}
        />
      </Section>

      <Section topMd={170} topLg={145} topXl={90}>
        <FeaturesSectionStyle3
          sectionTitle={t("treatmentSecSubtitle")}
          sectionTitleUp={t("treatmentSecTitle")}
          data={structuredData.treatments}
        />
      </Section>

      {/* Start Appointment Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection
          sectionTitle={t("appointmentComponent.subTitle")}
          sectionTitleUp={t("appointmentComponent.title")}
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle7
          imgUrl="/images/departments/banner_img_3.png"
          bgUrl="/images/departments/banner_bg_3.svg"
          title={t("bannerTitle")}
          subTitle={t("bannerSubtitle")}
        />
      </Section>
    </>
  );
}
