import React from "react";
import { Link } from "react-router-dom";

export default function PostStyle2({ data, variant, publishedAt, slug }) {
  return (
    <div className="cs_post cs_style_2 cs_radius_20 overflow-hidden">
      <Link to={`/blog/${slug}`} className="cs_post_thumb cs_view_mouse">
        <img
          src={`${process.env.REACT_APP_API_URL}/api/images/${data?.image}`}
          alt={data?.title}
        />
      </Link>
      <div className="cs_post_info">
        <div>
          <h2 className="cs_post_title cs_semibold cs_fs_24">
            <Link to={`/blog/${slug}`}>{data?.title}</Link>
          </h2>
          <div className="cs_posted_by">
            {new Date(publishedAt)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
              .replace(/ /g, ".")}
          </div>
        </div>
      </div>
    </div>
  );
}
