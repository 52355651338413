import React, { useEffect, useState } from "react";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import Section from "../Section";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import DoctorDetailsSection from "../Section/DoctorDetailsSection";
import AppointmentSectionStyle2 from "../Section/AppointmentSection/AppointmentSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import { useFetchData } from "../../App";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const fetchDoctors = async (lang, doctorId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/team/teams/${doctorId}?lang=${lang}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch doctors");
  }
  return response.json();
};
export default function DoctorDetails() {
  const { doctorId } = useParams();
  // Use the custom hook to fetch the data, passing the query key and fetching function
  const { data, error, isLoading } = useFetchData(["doctors"], (lang) =>
    fetchDoctors(lang, doctorId)
  );
  const { t } = useTranslation("common");

  const [structuredData, setStructuredData] = useState({
    social: [],
    contact: [],
    schedules: [],
    degrees: [],
    experiences: [],
    awards: [],
    doctorMap: {},
  });

  useEffect(() => {
    // Initialize a temporary structure to hold the categorized data
    const tempData = {
      social: [],
      contact: [],
      schedules: [],
      degrees: [],
      experiences: [],
      awards: [],
      doctorMap: {},
    };

    // Iterate over the doctor data keys
    for (const key in data?.data) {
      if (data?.data.hasOwnProperty(key)) {
        const value = data?.data[key];

        // Check for social-related keys
        if (key.startsWith("socialIcon")) {
          const index = key.replace("socialIcon", ""); // Get the index (one, two, etc.)
          const socialLinkKey = `socialLink${index}`;
          const socialTitleKey = `socialTitle${index}`;
          tempData.social.push({
            icon: value,
            link: data.data[socialLinkKey] || "",
            title: data.data[socialTitleKey] || "",
          });
        } else if (key.startsWith("contactIcon")) {
          const index = key.replace("contactIcon", ""); // Get the index (one, two, etc.)
          const contactTitleKey = `contactTitle${index}`;
          tempData.contact.push({
            icon: value,
            title: data.data[contactTitleKey] || "",
          });
        } else if (key.startsWith("scheduleDay")) {
          const index = key.replace("scheduleDay", ""); // Get the index (one, two, etc.)
          const timeKey = `scheduleTime${index}`;
          tempData.schedules.push({
            day: value,
            time: data.data[timeKey] || "",
          });
        } else if (key.startsWith("degreesTitle")) {
          const index = key.replace("degreesTitle", ""); // Get the index (one, two, etc.)
          const subtitleKey = `degreesSubtitle${index}`;
          tempData.degrees.push({
            title: value,
            subtitle: data.data[subtitleKey] || "",
          });
        } else if (key.startsWith("experiencesTitle")) {
          if (value !== "") {
            tempData.experiences.push(value);
          }
        } else if (key.startsWith("awardsTitle")) {
          if (value !== "") {
            tempData.awards.push(value);
          }
        } else {
          // Add other non-categorized data to the doctor map
          tempData.doctorMap[key] = value;
        }
      }
    }

    // Update the state with the categorized data
    setStructuredData(tempData);
  }, [data]);
  pageTitle("Doctor Details");

  if (isLoading) return "loading..";

  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <DoctorDetailsSection
          bgUrl="/images/doctors/doctor_details_bg.svg"
          imgUrl={`${process.env.REACT_APP_API_URL}/api/images/${data?.data?.image}`}
          name={data?.data?.name}
          department={data?.data?.designation}
          designation={data?.data?.description}
          description={data?.data?.description}
          social={structuredData.social}
          contactInfo={structuredData.contact}
          contactInfoHeading={t("teamPage.contact")}
          schedules={structuredData.schedules}
          scheduleHeading={t("teamPage.appointment")}
          degrees={structuredData.degrees}
          degreesHeading={t("teamPage.degrees")}
          experiences={structuredData.experiences}
          experiencesHeading={t("teamPage.experiences")}
          awards={structuredData.awards}
          awardHeading={t("teamPage.awards")}
        />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="/images/home_2/appointment_img.png"
          sectionTitle={t("appointmentComponent.subTitle")}
          sectionTitleUp={t("appointmentComponent.title")}
        />
      </Section>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title={t("bannerTitle")}
          subTitle={t("bannerSubtitle")}
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
