import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en", // اللغة الافتراضية في حالة عدم العثور على الترجمة
    debug: false,
    interpolation: {
      escapeValue: false, // ليس ضروريًا لـ React
    },
    lng: localStorage.getItem("i18nextLng") || "en",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // مسار ملفات الترجمة
    },
    ns: [
      "home",
      "common",
      "footer",
      "header",
      "about",
      "services",
      "contactUs",
    ], // أسماء ملفات الترجمة أو المجالات
    defaultNS: "home", // المجال الافتراضي
  });

export default i18n;
