import React, { useEffect, useState } from "react";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import Section from "../Section";
import BlogSectionStyle2 from "../Section/BlogSection/BlogSectionStyle2";
import Breadcrumb from "../Breadcrumb";
import { pageTitle } from "../../helpers/PageTitle";
import { fetchBlogs } from "./Home";
import { useFetchData } from "../../App";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function Blog() {
  const {
    data: blogsData,
    error: blogsError,
    isLoading: blogsIsLoading,
  } = useFetchData(["blogs"], fetchBlogs);
  pageTitle("Blog");
  const { t } = useTranslation("common");

  const [blogs, setBlogs] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!blogsData) return
    const category = searchParams.get('category');
    const tag = searchParams.get('tag');
    if (category) {
      filterBlogs(category, null)
    } else if (tag) {
      filterBlogs(null, tag)
    } else {
      setBlogs(blogsData?.data?.data)
    }
  }, [blogsData, searchParams]);
  // Function to filter blogs based on category or tag
  const filterBlogs = (category, tag) => {
    let filtered = blogsData?.data?.data;

    if (category) {
      filtered = filtered.filter(blog => blog.data.category[0].includes(category));
    }
    if (tag) {
      filtered = filtered.filter(blog => blog.data.tags.includes(tag));

    }
    setBlogs(filtered);
  };
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title={t("blogMainTitle")} />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogs} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title={t("bannerTitle")}
          subTitle={t("bannerSubtitle")}
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
